import React from 'react'
import {Modal, Button} from 'react-bootstrap'
import {Link} from 'gatsby'
import 'components/css/modal.css'
import {Span} from 'components'

function Sales() {
  return (
    <div className="modal-view">
      <Link to="/careers" state={{ modal: false }}>
        <Button className="close" id="close-button">
         <Span aria-hidden="true">x</Span>
        </Button>
      </Link>

      <h2>KEY ACCOUNT MANAGER</h2><hr/>
      <br/>
      <Modal.Title><h4>MINIMUM QUALIFICATIONS</h4></Modal.Title>
      <Modal.Body>
        <ul>
          <li>Min 3 to 5 Year Experience in Sales/Distribution/Telecom/ Channel Management/ Retail</li>
          <li>College graduate</li>
          <li>Good understanding of the retail market and competition in the relevant area</li>
          <li>Sales-driven personality, self-confident</li>
          <li>Experience in planning & executing sales or marketing strategies with results</li>
          <li>Must have experience in fieldwork</li>
          <li>Accomplishing tasks from the given location</li>
          <li>Love traveling</li>
          <li>Has drive and initiative, self-motivated</li>
          <li>Actions and results oriented</li>
          <li>Responsible and committed</li>
          <li>Basic IT skills (MS Office etc)</li>
          <li>Excellent communication skill & should be very good in local language</li>
          <li>Prepare and submit reports to their respective managers</li>
        </ul>
      </Modal.Body>
        <a href="mailto: careers@digipay.ph" className="resume"><Button className="resume-button">Send Resume</Button></a>
    </div>
  )
}

export default Sales
